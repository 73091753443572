//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Progress",
  props: {
    title: {
      type: String,
      default: "",
    },
    paramsobj: {
      type: Array,
      default: function() {
        return [];
      },
    },
    showName: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    cleartext(text) {
      let value = "";
      let background = "";
      // console.log(text)
      switch (text) {
        case "CORTime":
          value = "工作";
          background = "#73e8ed";
          break;
        case "工作":
          value = "工作";
          background = "#73e8ed";
          break;
        case "ETTime":
          value = "娱乐";
          background = "#ffef67";
          break;
        case "娱乐":
          value = "娱乐";
          background = "#ffef67";
          break;
        case "FreeTime":
          value = "空闲";
          background = "#ff6565";
          break;
        case "空闲":
          value = "空闲";
          background = "#ff6565";
          break;
        case "未知":
          value = "未知";
          background = "#3bc8f1";
          break;
        case "UnknownTime":
          value = "未知";
          background = "#3bc8f1";
          break;
        case "离线":
          value = "离线";
          background = "#ebebeb";
          break;
        case "NotExistTime":
          value = "离线";
          background = "#ebebeb";
          break;
        case "CORTimeWork":
          value = "工作";
          background = "#73e8ed";
          break;
        case "UnknownTimeWork":
          value = "未知";
          background = "#3bc8f1";
          break;
        case "FreeTimeWork":
          value = "空闲";
          background = "#ff6565";
          break;
        case "ETTimeWork":
          value = "娱乐";
          background = "#ffef67";
          break;
        case "NotExistTimeWork":
          value = "离线";
          background = "#ebebeb";
          break;
        default:
          value = text;
          break;
      }
      return {
        value,
        background,
      };
    },
    clickBar(item) {
      item.name = this.cleartext(item.name).value;
      this.$emit("getBar", item);
    },
  },

  mounted() {},
};
